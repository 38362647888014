import CollectionsOutlinedIcon from "@mui/icons-material/CollectionsOutlined";
import { Link, Stack, Typography } from "@mui/material";
import { HTMLAttributes } from "react";
import styled from "styled-components";
import { ReactComponent as BikeBlue } from "../../assets/images/bike_b.svg";
import { ReactComponent as BikeYellow } from "../../assets/images/bike_y.svg";
import location from "../../assets/images/location.png";
import { ReactComponent as CarBlue } from "../../assets/images/pcar_b.svg";
import { ReactComponent as CarYellow } from "../../assets/images/pcar_y.svg";
import { intervalMapping, tempImages } from "../../constants";
import { ICardTypeDetails } from "../stepTwo";
import { ICarParkInfo } from "../stepperContainer/type";
// 2 is bike , 1 is car
// interface IPrevSelectedData {
//   carParkTitle: string;
//   parkingAddressText: string;
//   carParkGoogleMapLink: string;
//   carType: string;
//   carTypeTitle: string;
//   price: number;
// }

const PrevSelectedData = ({
  carParkInfo,
  selectedCarType,
  selectedDate,
}: {
  carParkInfo: ICarParkInfo;
  selectedCarType: ICardTypeDetails;
  selectedDate?: Date;
}) => {
  const {
    carParkTitle,
    parkingAddressText,
    carParkGoogleMapLink,
    carParkId,
    carParkImage,
  } = carParkInfo;

  const unitText = () => {
    if (selectedCarType.interval_count > 1) {
      return `/${selectedCarType.interval_count}個${intervalMapping[selectedCarType.interval as keyof typeof intervalMapping]}`;
    } else {
      return `/${intervalMapping[selectedCarType.interval as keyof typeof intervalMapping]}`;
    }
  };

  // TODO: remove this
  const TMPURL =
    tempImages[carParkInfo.carParkId as unknown as keyof typeof tempImages];

  return (
    <Stack gap={1}>
      <Typography
        variant="h5"
        color="white"
        sx={{ fontWeight: "bold", mb: "0.3rem" }}
      >
        {carParkTitle} {selectedCarType.isFull && <span></span>}
      </Typography>

      <Stack direction="row" justifyContent="space-between">
        <Link
          variant="h6"
          color="waspYellow"
          href={carParkGoogleMapLink}
          target="_blank"
          alignItems="center"
        >
          <img src={location} width={20} height={20} />
          {parkingAddressText}
        </Link>
        {TMPURL !== "" && (
          <a href={TMPURL} target="_blank">
            <CollectionsOutlinedIcon style={{ fill: "white" }} />
          </a>
        )}
      </Stack>

      <CarTypeWrapper fulled={selectedCarType.isFull ? 1 : 0}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-end"
          width="100%"
        >
          <Stack direction="column" gap={1} justifyContent="space-between">
            <Stack direction="row" gap={1} alignItems="center">
              {(selectedCarType.isFull && (
                <>
                  {selectedCarType.itemTypeId === "2" ? (
                    <BikeYellow width={40} height={40} />
                  ) : (
                    <CarYellow width={40} height={40} />
                  )}
                </>
              )) || (
                <>
                  {selectedCarType.itemTypeId === "2" ? (
                    <BikeBlue width={40} height={40} />
                  ) : (
                    <CarBlue width={40} height={40} />
                  )}
                </>
              )}

              <Typography sx={{ fontWeight: "900" }} variant="caption">
                {selectedCarType.title}
              </Typography>
            </Stack>
            <Stack direction="row" alignItems="baseline">
              <Typography sx={{ fontWeight: "900" }} variant="h5">
                {selectedCarType.interval_count > 1
                  ? (
                      selectedCarType.price / selectedCarType.interval_count
                    ).toLocaleString("zh-HK", {
                      style: "currency",
                      currency: "HKD",
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })
                  : selectedCarType.price.toLocaleString("zh-HK", {
                      style: "currency",
                      currency: "HKD",
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })}
              </Typography>
              <Typography
                sx={{ fontWeight: "900" }}
                variant="body1"
              >{`${unitText()} ${
                selectedCarType.isFull ? "(已滿)" : ""
              }`}</Typography>
            </Stack>
          </Stack>
          {/* {selectedCarType.isFull && <Stack>登記輪候</Stack>} */}
          {selectedCarType.isFull && <Stack></Stack>}
        </Stack>
      </CarTypeWrapper>
    </Stack>
  );
};

const CarTypeWrapper = styled.div<HTMLAttributes<"div"> & { fulled: 1 | 0 }>`
  display: flex;
  font-weight: bold;
  border-radius: 0.5rem;
  padding: 0.5rem;
  background-color: ${(props) => (props.fulled ? "#842822" : "#fac80b")};
  color: ${(props) => (props.fulled ? "white" : "black")};
`;

export default PrevSelectedData;
