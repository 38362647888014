import { Stack, Typography } from "@mui/material";
import styled from "styled-components";
import { ICardTypeDetails, TCarTypeCardStyleProps } from ".";
import { ReactComponent as ArrowYellow } from "../../assets/images/arrow_y.svg";
import { ReactComponent as BikeYellow } from "../../assets/images/bike_y.svg";
import { ReactComponent as CarYellow } from "../../assets/images/pcar_y.svg";
import { getAmountUnitText } from "../../helper";

const FulledCarTypeCard = ({
  carTypeDetails,
  isSelected,
  onCarTypeSelect,
}: {
  carTypeDetails: ICardTypeDetails;
  isSelected: boolean;
  onCarTypeSelect: (id: string) => void;
}) => {
  const unitText = getAmountUnitText(
    carTypeDetails.interval,
    carTypeDetails.interval_count
  );

  return (
    <CarTypeWrapper
      selected={isSelected}
      onClick={() => onCarTypeSelect(carTypeDetails.orderItemId)}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
      >
        <Stack direction="column" gap={2} justifyContent="space-between">
          <Stack direction="row" gap={1} alignItems="center">
            {carTypeDetails.itemTypeId === "2" ? (
              <BikeYellow width={40} height={40} />
            ) : (
              <CarYellow width={40} height={40} />
            )}
            <Typography sx={{ fontWeight: "900" }} variant="caption">
              {carTypeDetails.title}
            </Typography>
          </Stack>
          <Stack direction="column">
            {carTypeDetails.interval_count > 1 && (
              <Typography sx={{ fontWeight: "900" }} variant="caption">
                {`季票計劃(${carTypeDetails.interval_count}個月)`}
              </Typography>
            )}

            <Stack direction="row" alignItems="baseline">
              <Typography sx={{ fontWeight: "900" }} variant="h6">
                {carTypeDetails.interval_count > 1
                  ? (
                      carTypeDetails.price / carTypeDetails.interval_count
                    ).toLocaleString("zh-HK", {
                      style: "currency",
                      currency: "HKD",
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })
                  : carTypeDetails.price.toLocaleString("zh-HK", {
                      style: "currency",
                      currency: "HKD",
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })}
              </Typography>
              <Typography sx={{ fontWeight: "900" }} variant="caption">
                {`${unitText}(已滿)`}
              </Typography>
            </Stack>

            {carTypeDetails.interval_count > 1 && (
              <Typography sx={{ fontWeight: "900" }} variant="caption">
                *需要一次性付款
              </Typography>
            )}
          </Stack>
        </Stack>

        <Stack direction="row">
          <Stack
            direction="column"
            gap={2}
            justifyContent="center"
            alignItems="center"
            minWidth="60px"
          >
            登記輪候
          </Stack>

          <ArrowYellow width={30} height={30} />
        </Stack>
      </Stack>
    </CarTypeWrapper>
  );
};

const CarTypeWrapper = styled.div<TCarTypeCardStyleProps>`
  display: flex;
  font-weight: bold;
  border-radius: 0.5rem;
  padding: 0.5rem;
  background-color: #842822;
  color: white;
  cursor: pointer;
  border: ${(props) =>
    props.selected ? "3px solid white" : "3px solid #842822"};
`;

export default FulledCarTypeCard;
