export const intervalMapping = {
  year: "年",
  month: "月",
  week: "周",
  day: "日",
};

export const tempImages = {
  1: "",
  2: "",
  3: "",
  4: "",
  5: "",
  6: "",
  7: "https://www.google.com.hk/maps/place/AI+Parking+Bees+%E6%99%BA%E8%83%BD%E5%81%9C%E8%BB%8A%E5%A0%B4%EF%BC%8D%E7%81%A3%E4%BB%94%E6%B5%B7%E5%A4%96%E4%BF%A1%E8%A8%97%E9%8A%80%E8%A1%8C%E5%A4%A7%E5%BB%88/@22.2797006,114.1777905,3a,75y,90t/data=!3m8!1e2!3m6!1sAF1QipMzbdSOU-t1je0lCoAp8BHGguY_NN1LY-ckKqyI!2e10!3e12!6shttps:%2F%2Flh5.googleusercontent.com%2Fp%2FAF1QipMzbdSOU-t1je0lCoAp8BHGguY_NN1LY-ckKqyI%3Dw397-h298-k-no!7i2000!8i1500!4m9!3m8!1s0x34040159ffe3f677:0xad5ee7d4e7837800!8m2!3d22.2797006!4d114.1777905!10e5!14m1!1BCgIgARICEAE!16s%2Fg%2F11ln187f7f?hl=zh-TW&entry=ttu&g_ep=EgoyMDI0MTAwNy4xIKXMDSoASAFQAw%3D%3D",
  8: "https://www.google.com.hk/maps/place/AI+Parking+Bees+%E6%99%BA%E8%83%BD%E5%81%9C%E8%BB%8A%E5%A0%B4%EF%BC%8D%E9%95%B7%E6%B2%99%E7%81%A3/@22.3398107,114.1506999,3a,75y,90t/data=!3m8!1e2!3m6!1sAF1QipPXqO3O89Xos6eCwRr3U0TNw-jRwF6ZzgkdFn79!2e10!3e12!6shttps:%2F%2Flh5.googleusercontent.com%2Fp%2FAF1QipPXqO3O89Xos6eCwRr3U0TNw-jRwF6ZzgkdFn79%3Dw203-h114-k-no!7i4032!8i2268!4m7!3m6!1s0x340407003ae4f979:0xbf9719c832ce5abd!8m2!3d22.3398688!4d114.1506767!10e5!16s%2Fg%2F11vwpgz25l?hl=zh-TW&entry=ttu&g_ep=EgoyMDI0MTAwNy4xIKXMDSoASAFQAw%3D%3D",
  9: "https://www.google.com.hk/maps/place/AI+Parking+Bees+%E6%99%BA%E8%83%BD%E5%81%9C%E8%BB%8A%E5%A0%B4%EF%BC%8D%E7%89%9B%E9%A0%AD%E8%A7%92/@22.3123124,114.2200564,3a,75y,90t/data=!3m8!1e2!3m6!1sAF1QipPUZQKqzErD87czYs-5xoD0xG5Ilf8Wj1x9Pv2C!2e10!3e12!6shttps:%2F%2Flh5.googleusercontent.com%2Fp%2FAF1QipPUZQKqzErD87czYs-5xoD0xG5Ilf8Wj1x9Pv2C%3Dw397-h298-k-no!7i1080!8i810!4m9!3m8!1s0x34040147b083d0fb:0x3c938355e75046a1!8m2!3d22.3123124!4d114.2200564!10e5!14m1!1BCgIgARICEAE!16s%2Fg%2F11wj5h0df3?hl=zh-TW&entry=ttu&g_ep=EgoyMDI0MTAwNy4xIKXMDSoASAFQAw%3D%3D",
};
