import {
  Box,
  Button,
  List,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import BannerSwiper from "../../components/swiper";
import ValidationButton from "../../components/validationButton";

import dayjs from "dayjs";
import { getMonthlyOrdersByPhone } from "../../apis/order";
import { IGetMonthlyOrderResponse } from "../../apis/order/type";
import { verifyOtp } from "../../apis/otp";
import { createCustomerPortal } from "../../apis/subscription";
import Banner1 from "../../assets/images/banner1.png";

const SWIPER_OBJECT = [
  { image: Banner1, link: "https://goswap.com.hk/rent-with-park" },
  // { image: Banner2, link: "https://mrwrapper.com.hk/" },
];

interface IChangePaymentTarget {
  name: string;
  email: string;
  phone: string;
  subscriptionId: string;
  last4Digit?: string;
}
const InquiryForm: React.FC = () => {
  const [phone, setPhone] = useState("");
  const [validationCode, setValidationCode] = useState("");
  const [errorMessages, setErrorMessages] = useState({
    phone: "",
    validationCode: "",
    stripe: "",
  });

  const [inquireData, setInquireData] = useState<IGetMonthlyOrderResponse[]>();
  const [isFetching, setIsFetching] = useState(false);
  const [managementUrl, setManagementUrl] = useState("");

  const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPhone(event.target.value);
  };

  const handleValidationCodeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setValidationCode(event.target.value);
  };

  const fetchCreateStripeCustomerPortal = async (customerId: string) => {
    try {
      const portalResponse = await createCustomerPortal(customerId);
      if (portalResponse.url) {
        setManagementUrl(portalResponse.url);
      }
    } catch (error) {
      console.log("error?");
    }
  };

  const formValidator = (key: "phone" | "validationCode", value: string) => {
    switch (key) {
      case "phone":
        if (value.trim().length === 0) {
          setErrorMessages({ ...errorMessages, phone: "請輸入電話號碼" });
        } else {
          setErrorMessages({ ...errorMessages, phone: "" });
        }
        break;
      case "validationCode":
        if (value.trim().length === 0) {
          setErrorMessages({
            ...errorMessages,
            validationCode: "請輸入認證碼",
          });
        } else {
          setErrorMessages({ ...errorMessages, validationCode: "" });
        }
        break;

      default:
        break;
    }
  };
  // verify otp and query action
  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    verifyOtpAction();
  };

  // verify otp api
  const verifyOtpAction = async () => {
    try {
      if (
        process.env.REACT_APP_ENV === "dev" ||
        process.env.REACT_APP_ENV === "LOCAL"
      ) {
        // bypass otp
        await fetchSubscriptionHistory();
        return;
      }

      const verifyResult = await verifyOtp(phone, validationCode);
      if (verifyResult.message === "success") {
        await fetchSubscriptionHistory();
      } else {
        setErrorMessages({
          ...errorMessages,
          validationCode: "驗證碼錯誤",
        });
      }
    } catch (error) {
      setErrorMessages({
        ...errorMessages,
        validationCode: "驗證碼錯誤",
      });
    }
  };

  // fetch subscription history
  const fetchSubscriptionHistory = async () => {
    try {
      setIsFetching(true);
      const inquireResult = await getMonthlyOrdersByPhone(phone);
      if (inquireResult) {
        setIsFetching(false);
        setInquireData(inquireResult);
      }
    } catch (error) {
      setIsFetching(false);
    } finally {
      setIsFetching(false);
    }
  };

  const handleModalClose = () => {
    setManagementUrl("");
  };

  // reset all state when re-inquire
  useEffect(() => {
    setIsFetching(false);
    handleModalClose();
  }, [inquireData]);

  return (
    <Stack gap={2} justifyContent="center" width="100%">
      <Typography variant="h4" color="white" fontWeight={900}>
        查詢訂閱記錄
      </Typography>

      <Box
        bgcolor="white"
        component="form"
        sx={{
          display: "flex",
          flexDirection: "column",
          p: 2,
          borderRadius: 2,
          gap: 1,
        }}
        onSubmit={handleSubmit}
        autoComplete="off"
      >
        <TextField
          required
          id="phone"
          label="電話"
          fullWidth
          variant="filled"
          value={phone}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            const digitOnlyRegex = /^[0-9]{0,8}$/;
            if (digitOnlyRegex.test(event.target.value)) {
              formValidator("phone", event.target.value);
              handlePhoneChange(event);
            }
          }}
          InputProps={{
            endAdornment: <ValidationButton phone={phone} />,
          }}
          error={errorMessages.phone.length > 0}
          helperText={errorMessages.phone}
        />
        <TextField
          required
          id="validationCode"
          label="驗證碼"
          fullWidth
          variant="filled"
          value={validationCode}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            formValidator("validationCode", event.target.value);
            handleValidationCodeChange(event);
          }}
          inputProps={{ maxLength: 6 }}
          error={errorMessages.validationCode.length > 0}
          helperText={errorMessages.validationCode}
        />
        <Button
          variant="contained"
          fullWidth
          disabled={phone.length === 0 || validationCode.length === 0}
          sx={{
            background: "waspYellow",
            color: "black",
            ":hover": {
              backgroundColor: "waspYellow",
              color: "black",
            },
            ":disabled": {
              backgroundColor: "grey",
              color: "waspLightYellow",
            },
          }}
          type="submit"
        >
          查詢
        </Button>
      </Box>

      {inquireData && (
        <>
          <List sx={{ bgcolor: "waspLightDark" }}>
            <Typography variant="h6" textAlign="center" color="white">
              訂閱歷史
            </Typography>
            {(isFetching && (
              <Box textAlign="center" p={4}>
                Loading...
              </Box>
            )) || (
              <>
                {(inquireData?.length > 0 &&
                  inquireData.map((record) => {
                    const _isActive = record.monthly_order_state_id === 2;
                    const _isFreeTrailing = record.monthly_order_state_id === 1;

                    return (
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        p={2}
                        gap={1}
                        key={record.subscription_id}
                        sx={{
                          ":hover": {
                            backgroundColor: "waspYellow",
                            color: "waspLightDark",
                          },

                          borderBottom: "1px solid white",
                        }}
                      >
                        {/* details section */}
                        <Stack gap={1} flex={2}>
                          <Stack direction="row" fontSize={12}>
                            編號: {record.ref_no}
                          </Stack>

                          <Stack direction="row" fontSize={12}>
                            車牌: {record.u_lpn}
                          </Stack>

                          <Stack
                            direction="row"
                            fontSize={13}
                          >{`停車場: ${record.parking.display_name}`}</Stack>

                          <Stack
                            direction="row"
                            fontSize={13}
                          >{`項目: ${record.monthlyOrderItem?.name}`}</Stack>

                          <Stack direction="row" fontSize={13}>
                            {`開始日期: ${dayjs(record.start_date).utc().format("YYYY-MM-DD")}`}
                          </Stack>

                          {!_isActive && !_isFreeTrailing && (
                            <Stack direction="row" fontSize={13}>
                              {`完結日期: ${dayjs(record.end_date).utc().format("YYYY-MM-DD")}`}
                            </Stack>
                          )}
                        </Stack>

                        {/* button section */}
                        <Stack gap={2} flex={1}>
                          {!_isFreeTrailing && (
                            <Button
                              variant="contained"
                              onClick={(e) => {
                                e.stopPropagation();
                                fetchCreateStripeCustomerPortal(
                                  record.customer_id
                                );
                              }}
                              sx={{ width: "100%" }}
                            >
                              前往管理
                            </Button>
                          )}
                          {!_isActive && (
                            <Button
                              disabled
                              variant="contained"
                              sx={{
                                width: "100%",
                                display: _isFreeTrailing ? "none" : "block",
                                ":disabled": {
                                  backgroundColor: "grey",
                                  color: "white",
                                },
                              }}
                            >
                              已取消
                            </Button>
                          )}
                        </Stack>
                      </Stack>
                    );
                  })) || (
                  <Typography variant="h4" fontWeight={900} textAlign="center">
                    查無訂閱記錄
                  </Typography>
                )}
              </>
            )}
          </List>
        </>
      )}

      <Modal open={managementUrl !== ""} onClose={handleModalClose}>
        <Box sx={modalStyle}>
          <Typography
            color="waspDark"
            textAlign="center"
            variant="h6"
            component="h2"
            mb={4}
          >
            {`你確定要前往管理頁面嗎？`}
          </Typography>
          <Stack direction="row" gap={2}>
            <Button
              onClick={handleModalClose}
              variant="contained"
              fullWidth
              sx={{
                backgroundColor: "waspDark",
                color: "white",
                ":hover": {
                  backgroundColor: "waspDark",
                  color: "white",
                },
              }}
            >
              返回
            </Button>
            <Button
              onClick={() => {
                window.open(managementUrl, "_blank");
              }}
              variant="contained"
              fullWidth
              sx={{
                color: "white",
                backgroundColor: "waspYellow",
                ":hover": {
                  color: "white",
                  backgroundColor: "waspYellow",
                },
              }}
            >
              確認
            </Button>
          </Stack>
        </Box>
      </Modal>
      <Box>
        <BannerSwiper slideObject={SWIPER_OBJECT} />
      </Box>
    </Stack>
  );
};

const modalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 340,
  bgcolor: "white",
  borderRadius: 4,
  boxShadow: 24,
  p: 4,
};

export default InquiryForm;
